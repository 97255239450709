.button.flat{ 
  padding: 1em;

  &:before{
    content: "";
    display: block;
    @include absolute_all();
    background-color: rgba(#000, 0.3);
    z-index: -1;
    opacity: 0;
    transition: all 0.4s; 
  }

  &:hover{
    &:before{
      opacity: 1;
    }
  }

  /*----------  Colors  ----------*/
  @each $color-name, $color-code in $btncolors {
    &.#{$color-name} {
      background-color: $color-code; 
      border-color: $color-code; 
      color: #FFF;
      fill: #FFF;
 
      &:hover{ 
        background-color:  palette(brand, contrast);
        border-color:  palette(brand, contrast);
        color: #FFF;
        fill: #FFF;
        
      }
    }
  }

  /*----------  Custom  ----------*/

  &.white{
    border-color: #fff;
    background: #fff;
    color: palette(brand, primary);
    fill: palette(brand, primary);

    &:hover{
      color: palette(brand, contrast);
      fill: palette(brand, contrast);
    }
  }
}